<template>
  <div>
    <div class="row" v-show="false">
      <div class="col-md-12">
        <b-alert
          show
          variant="light"
          class="alert alert-custom alert-white alert-shadow fade show gutter-b"
        >
          <div class="alert-icon">
            <span class="svg-icon svg-icon-lg">
              <inline-svg src="media/svg/icons/Tools/Compass.svg" />
            </span>
          </div>
          <div class="alert-text">
            <b>Data tables</b> The <code>v-data-table</code> component is used
            for displaying tabular data. Features include sorting, searching,
            pagination, inline-editing, header tooltips, and row selection.
            <a
              class="font-weight-bold"
              href="https://vuetifyjs.com/en/components/data-tables"
              target="_blank"
            >
              See documentation.
            </a>
          </div>
        </b-alert>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <v-card :loading="regUsersSaveLoading">
          <template slot="progress">
            <v-overlay absolute class="d-flex flex-column text-center">
              <div>
                <v-progress-circular
                  size="150"
                  color="#1BC5BD "
                  :value="regUsersSaveLoading"
                  indeterminate
                  ref="savingLoader"
                >
                  <span>{{
                    $t("PAGES.COMPANIES.FORM.MANAGE.SAVING_LOADING")
                  }}</span>
                </v-progress-circular>
              </div>
            </v-overlay>
          </template>
          <v-card-title>
            {{ $t("PAGES.USERS.NEW.TITLE") }}
          </v-card-title>
          <b-card-body>
            <v-card-text>
              <v-form ref="new_user_form" v-model="valid" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="6" sm="6">
                      <v-text-field
                        v-model="user.username"
                        :rules="usernameRules"
                        filled
                        dense
                        color="blue-grey lighten-2"
                        :label="$t('PAGES.USERS.ATTRIBUTES.USERNAME')"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" sm="6">
                      <v-autocomplete
                        v-model="user.member_group"
                        :items="dataMemberGroupList.data"
                        filled
                        dense
                        color="blue-grey lighten-2"
                        :label="$t('PAGES.USERS.ATTRIBUTES.MEMBER_GROUP')"
                        item-text="group_name"
                        item-value="id"
                        required
                        :rules="requiredRules"
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="4">
                      <v-text-field
                        v-model="user.email"
                        :rules="emailRules"
                        filled
                        dense
                        color="blue-grey lighten-2"
                        :label="$t('PAGES.USERS.ATTRIBUTES.EMAIL')"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-spacer></v-spacer>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="user.password"
                        filled
                        dense
                        color="blue-grey lighten-2"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show1 ? 'text' : 'password'"
                        :hint="$t('PAGES.USERS.ATTRIBUTES.PASSWORD_HINT')"
                        counter
                        :error="passwordError"
                        @click:append="show1 = !show1"
                        :label="$t('PAGES.USERS.ATTRIBUTES.PASSWORD')"
                        required
                        :rules="requiredRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="repassword"
                        filled
                        dense
                        color="blue-grey lighten-2"
                        class="input-group--focused"
                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show2 ? 'text' : 'password'"
                        :hint="$t('PAGES.USERS.ATTRIBUTES.PASSWORD_HINT')"
                        counter
                        :error="passwordError"
                        @click:append="show2 = !show2"
                        :label="$t('PAGES.USERS.ATTRIBUTES.PASSWORD')"
                        required
                        :rules="requiredRules"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="2">
                      <v-btn color="success" class="mr-4" @click="validate">
                        {{ $t("COMMON.BUTTON.SAVE") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <div style="flex: 1 1 auto"></div>
          </b-card-body>
        </v-card>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}

.v-application code {
  box-shadow: none !important;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import { FETCH_MEMBER_GROUPS_LIST } from "@/core/services/store/member_groups.module";
import { CREATE_REG_USER_DATA } from "@/core/services/store/reg_users.module";
import Swal from "sweetalert2";

export default {
  name: "NewUserForm",
  data() {
    return {
      items: ["foo", "bar", "fizz", "buzz"],
      numberRule: [
        (v) => {
          // if (!v.trim()) return true;
          if (!isNaN(parseInt(v))) return true;
          return this.$t("PAGES.USERS.FORM.MANAGE.ERROR_PHONE");
        }
      ],
      emailRules: [
        (v) => !!v || this.$t("PAGES.USERS.ATTRIBUTES.EMAIL_REQUIRED"),
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          this.$t("PAGES.USERS.ATTRIBUTES.EMAIL_ERROR")
      ],
      usernameRules: [
        (v) => !!v || this.$t("PAGES.USERS.ATTRIBUTES.USERNAME_REQUIRED"),
        (v) =>
          (v && v.length > 4) ||
          this.$t("PAGES.USERS.ATTRIBUTES.MIN_LENGHT_USERNAME")
      ],
      requiredRules: [(v) => !!v || this.$t("COMMON.ERRORS.REQUIRED")],
      show1: false,
      show2: false,
      password: "",
      repassword: "password",
      passwordError: false,
      user: {
      },
      valid: true,
      group_filters: {
        query: [],
        filters: {},
        sort: {},
        page: 1
      },
      users_filters: {
        query: [],
        filters: {
          _c1: {
            "member_group.is_banned": {
              _eq: false
            }
          },
          _c2: {
            "member_group.is_admin": {
              _eq: true
            }
          },

          _cmb_or: "_c1,_c2"
        },
        sort: {},
        page: 1
      }
    };
  },
  computed: {
    ...mapGetters([
      "memberGroupsList",
      "regUsersSaveLoading",
      "getRegUsersApiErrors"
    ]),
    dataMemberGroupList() {
      return this.memberGroupsList;
    }
  },
  components: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("PAGES.USERS.AREA") },
      { title: this.$t("PAGES.USERS.NEW.TITLE") }
    ]);
    this.$store.dispatch(FETCH_MEMBER_GROUPS_LIST, this.group_filters);
  },
  methods: {
    validate() {
      if (this.$refs.new_user_form.validate()) {
        this.snackbar = true;
        this.user.status = 1;
        this.save();
      }
    },
    save() {
      if (
        this.passwordError ||
        this.repassword != this.user.password ||
        this.repassword.length < 8
      ) {
        this.passwordError = true;
        return false;
      }
      this.$store.dispatch(CREATE_REG_USER_DATA, this.user);

      this.finalDialog();
    },
    finalDialog() {
      Swal.fire({
        title: "",
        text: !this.getRegUsersApiErrors
          ? this.$t("SAVING_SUCCESS")
          : this.$t("SAVING_ERROR"),
        icon: !this.getRegUsersApiErrors ? "success" : "error",
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-light-primary"
        }
      });
      if (!this.getRegUsersApiErrors) {
        this.user = {};
        this.$router.push({ name: "users_list" });
      }
    }
  },
  watch: {
    repassword: function repassword() {
      if (this.user.password != this.repassword) {
        this.passwordError = true;
      } else {
        this.passwordError = false;
      }
    }
  }
};
</script>
